import React from 'react';
import Currency from './Currency';
import ShareButtons from './ShareButtons';

const ResultSummary = props => {
  const { endwertImmobilie, portfolioValueAfterTax } = props;
  const finalValueDifference = Math.abs(
    endwertImmobilie - portfolioValueAfterTax
  );
  const finalValueDifferencePercent = (
    (Math.max(endwertImmobilie, portfolioValueAfterTax) /
      Math.min(endwertImmobilie, portfolioValueAfterTax) -
      1) *
    100
  ).toFixed(1);

  const getSummaryBoxClassName = (a, b) => {
    const className = 'tile is-child notification';
    if (b / a <= 1.1) {
      return className + ' is-success';
    }
    if (b / a <= 1.25) {
      return className + ' is-warning';
    }
    return className + ' is-danger';
  };

  let classNameBuySummary = getSummaryBoxClassName(
    endwertImmobilie,
    portfolioValueAfterTax
  );
  let classNameRentSummary = getSummaryBoxClassName(
    portfolioValueAfterTax,
    endwertImmobilie
  );

  return (
    <React.Fragment>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className={classNameBuySummary}>
            <p className="title is-4 has-text-centered">Kauf</p>
            <p>
              Nach vollständiger Abzahlung des Kredits hat die Immobilie einen
              Wert von
            </p>
            <p className="has-text-centered">
              <strong>
                <Currency>{endwertImmobilie}</Currency>
              </strong>
            </p>
          </div>
        </div>
        <div className="tile is-parent">
          <div className={classNameRentSummary}>
            <h4 className="title is-4 has-text-centered">Miete</h4>
            <p>
              Bei Miete hättest Du stattdessen zum gleichen Zeitpunkt ein
              Finanzvermögen (nach Steuern) von
            </p>
            <p className="has-text-centered">
              <strong>
                <Currency>{portfolioValueAfterTax}</Currency>
              </strong>
            </p>
          </div>
        </div>
      </div>

      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="tile is-child notification is-info">
            <div className="title is-4 has-text-centered">
              {endwertImmobilie > portfolioValueAfterTax ? 'Kauf ' : 'Miete '}
              ist in diesem Szenario um{' '}
              <Currency>{finalValueDifference}</Currency> oder{' '}
              {finalValueDifferencePercent}% besser.
            </div>
            <p>
              Achtung, das Ergebnis hängt stark von den gemachten Annahmen (über
              Wertsteigerung, Kapitalertrag, ...) ab. Niemand weiß wie die
              Zukunft tatsächlich aussehen wird. Am besten Du probierst
              verschiedene Werte aus, um zu sehen wie sehr sich die Ergebnisse
              verändern können.{' '}
            </p>
          </div>
        </div>
      </div>
      <div className="has-text-right move-up">
        <ShareButtons />
      </div>
    </React.Fragment>
  );
};

export default ResultSummary;
