import { combineReducers } from 'redux';
import { CHANGE_MIETE, CHANGE_KAUF } from '../actions';

export default combineReducers({
  kauf,
  miete
});

function kauf(state = {}, action) {
  switch (action.type) {
    case CHANGE_KAUF:
      return Object.assign({}, state, action.kauf);
    default:
      return state;
  }
}

function miete(state = {}, action) {
  switch (action.type) {
    case CHANGE_MIETE:
      return Object.assign({}, state, action.miete);
    default:
      return state;
  }
}
