import React from 'react';
import { Link } from 'react-router-dom';
import madeWithBulma from '../assets/made-with-bulma.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="content has-text-centered">
        <p>
          Erstellt von <a href="https://www.wolfib.com/about">Wolfgang Beyer</a>
        </p>
        <p>
          <Link to="/impressum">Impressum</Link>
        </p>
        <p>
          <a href="https://bulma.io">
            <img
              src={madeWithBulma}
              alt="Made with Bulma"
              width="128"
              height="24"
            />
          </a>
        </p>
        <p>
          mit Grafiken von{' '}
          <a href="https://www.freepik.com/free-vector/colorful-collection-with-great-variety-of-avatars_1258263.htm">
            Freepik
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
