import { connect } from 'react-redux';
import MieteInputForm from './MieteInputForm';
import { changeMiete } from '../actions';
import { debounce } from 'debounce';

const mapStateToProps = state => {
  return {
    initialMiete: state.miete
  };
};

const mapDispatchToProps = dispatch => {
  const composed = miete => {
    dispatch(changeMiete(miete));
  };

  return {
    onChange: debounce(composed, 500)
  };
};

const MieteInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MieteInputForm);

export default MieteInputContainer;
