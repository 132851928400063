import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faShareSquare } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';

const openFacebookShare = (e, url) => {
  e.preventDefault();
  window.open(
    'https://facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.kaufodermiete.at',
    'facebook-share-dialog',
    'width=626,height=436'
  );
  return false;
};

const openTwitterShare = e => {
  e.preventDefault();
  window.open(
    'https://twitter.com/intent/tweet/?text=Immobilie%20Kaufen%20oder%20Mieten%3F%20Was%20ist%20besser%3F&amp;url=https%3A%2F%2Fwww.kaufodermiete.at',
    'twitter-share-dialog',
    'width=626,height=275'
  );
  return false;
};

const ShareButtons = ({ tabIndex }) => {
  return (
    <React.Fragment>
      <p>
        <FontAwesomeIcon
          icon={faShareSquare}
          size="lg"
          className="social-button"
        />
        Teilen:
        <a
          aria-label="facebook share"
          className="facebook-button social-button"
          rel="noopener"
          href="https://facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.kaufodermiete.at"
          onClick={e => openFacebookShare(e)}
          tabIndex={tabIndex ? tabIndex : null}
        >
          <FontAwesomeIcon icon={faFacebook} size="lg" />
        </a>
        <a
          aria-label="twitter share"
          className="tweet-button social-button"
          rel="noopener"
          href="https://twitter.com/intent/tweet/?text=Immobilie%20Kaufen%20oder%20Mieten%3F%20Was%20ist%20besser%3F&amp;url=https%3A%2F%2Fwww.kaufodermiete.at"
          onClick={e => openTwitterShare(e)}
          tabIndex={tabIndex ? tabIndex + 1 : null}
        >
          <FontAwesomeIcon icon={faTwitter} size="lg" />
        </a>
        <a
          className="email-button social-button"
          href="mailto:?subject=Immobilie%20Kaufen%20oder%20Mieten%3F%20Was%20ist%20besser%3F&amp;body=https%3A%2F%2Fwww.kaufodermiete.at"
          rel="noopener"
          aria-label="email share"
          tabIndex={tabIndex ? tabIndex + 2 : null}
        >
          <FontAwesomeIcon icon={faEnvelope} size="lg" />
        </a>
      </p>
    </React.Fragment>
  );
};

export default ShareButtons;
