import React from 'react';
import Currency from './Currency';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

export default class CashflowTable extends React.Component {
  years = (this.props.data.payments.length - 1) / 12;
  state = {
    isExpanded: new Array(this.years).fill(false)
  };

  round = (value, digits = 0) => {
    const factor = Math.pow(10, digits);
    return Math.round(value * factor) / factor;
  };

  createTableContent = () => {
    let rows = [];
    rows.push(this.renderMonth(0));
    for (let i = 1; i <= this.years; i++) {
      rows.push(this.renderYear(i));
      if (this.state.isExpanded[i - 1]) {
        for (let j = 1; j <= 12; j++) {
          rows.push(this.renderMonth(j + 12 * (i - 1)));
        }
      }
    }
    return rows;
  };

  handleRowClick(rowId) {
    let isExpanded = this.state.isExpanded;
    isExpanded[rowId] = !isExpanded[rowId];
    this.setState({ isExpanded: isExpanded });
  }

  renderYear = year => {
    const isExpanded = this.state.isExpanded[year - 1];
    const clickCallback = () => this.handleRowClick(year - 1);
    const yearData = this.props.data.payments.slice(
      (year - 1) * 12 + 1,
      year * 12 + 1
    );
    const loanPaymentSum = yearData.reduce(
      (sum, current) => sum + current.buy.loanPayment,
      0
    );
    const buyBetriebskostenSum = yearData.reduce(
      (sum, current) => sum + current.buy.betriebskostenKauf,
      0
    );
    const buyRepairCostsSum = yearData.reduce(
      (sum, current) => sum + current.buy.repairCosts,
      0
    );
    const buyTotalPaymentSum = yearData.reduce(
      (sum, current) => sum + current.buy.totalPayment,
      0
    );
    const rentPaymentSum = yearData.reduce(
      (sum, current) => sum + current.rent.rent,
      0
    );
    const rentBetriebskostenSum = yearData.reduce(
      (sum, current) => sum + current.rent.betriebskostenMiete,
      0
    );
    const rentRepairCostsSum = yearData.reduce(
      (sum, current) => sum + current.rent.repairCosts,
      0
    );
    const rentTotalPaymentSum = yearData.reduce(
      (sum, current) => sum + current.rent.totalPayment,
      0
    );
    const difference = yearData.reduce(
      (sum, current) => sum + current.difference,
      0
    );

    return (
      <tr key={'y' + year} onClick={clickCallback}>
        <td className="expand">
          {isExpanded ? (
            <FontAwesomeIcon icon={faAngleUp} />
          ) : (
            <FontAwesomeIcon icon={faAngleDown} />
          )}
        </td>
        <td>{year}</td>
        <td />
        <td>
          <Currency>{loanPaymentSum}</Currency>
        </td>
        <td>
          <Currency>{buyBetriebskostenSum}</Currency>
        </td>
        <td>
          <Currency>{buyRepairCostsSum}</Currency>
        </td>
        <td>
          <Currency>{buyTotalPaymentSum}</Currency>
        </td>
        <td>
          <Currency>{rentPaymentSum}</Currency>
        </td>
        <td>
          <Currency>{rentBetriebskostenSum}</Currency>
        </td>
        <td>
          <Currency>{rentRepairCostsSum}</Currency>
        </td>
        <td className="nowrap">
          {year === this.years && (
            <span
              className="tooltip"
              data-tooltip={`Enthält ${this.round(
                -this.props.data.payments[year * 12].rent.kaution,
                2
              )} zurückgezahlte Kaution`}
            >
              <FontAwesomeIcon icon={faQuestionCircle} />
              &nbsp;
            </span>
          )}
          <Currency>{rentTotalPaymentSum}</Currency>
        </td>
        <td>
          <Currency>{difference}</Currency>
        </td>
        <td>
          <Currency>
            {this.props.data.payments[year * 12].buy.totalValue}
          </Currency>
        </td>
        <td>
          <Currency>
            {this.props.data.payments[year * 12].portfolioValue}
          </Currency>
        </td>
      </tr>
    );
  };

  renderMonth = month => {
    const data = this.props.data.payments[month];
    let monthInYear = month % 12;
    if (monthInYear === 0 && month !== 0) monthInYear = 12;
    return (
      <tr key={'m' + month}>
        <td />
        <td>{month === 0 && '0'}</td>
        <td>{monthInYear}</td>
        <td>
          <Currency>{data.buy.loanPayment}</Currency>
        </td>
        <td>
          <Currency>{data.buy.betriebskostenKauf}</Currency>
        </td>
        <td>
          <Currency>{data.buy.repairCosts}</Currency>
        </td>
        <td className="nowrap">
          {month === 0 && (
            <span
              className="tooltip"
              data-tooltip="Anzahlung in Höhe der Eigenmittel"
            >
              <FontAwesomeIcon icon={faQuestionCircle} />
              &nbsp;
            </span>
          )}
          <Currency>{data.buy.totalPayment}</Currency>
        </td>
        <td>
          <Currency>{data.rent.rent}</Currency>
        </td>
        <td>
          <Currency>{data.rent.betriebskostenMiete}</Currency>
        </td>
        <td>
          <Currency>{data.rent.repairCosts}</Currency>
        </td>
        <td className="nowrap">
          {month === 0 && (
            <span className="tooltip" data-tooltip="Maklergebühren und Kaution">
              <FontAwesomeIcon icon={faQuestionCircle} />
              &nbsp;
            </span>
          )}
          {month === this.props.data.payments.length - 1 && (
            <span
              className="tooltip"
              data-tooltip={`Enthält ${this.round(
                -this.props.data.payments[month].rent.kaution,
                2
              )} zurückgezahlte Kaution`}
            >
              <FontAwesomeIcon icon={faQuestionCircle} />
              &nbsp;
            </span>
          )}
          <Currency>{data.rent.totalPayment}</Currency>
        </td>
        <td>
          <Currency>{data.difference}</Currency>
        </td>
        <td>
          <Currency>{data.buy.totalValue}</Currency>
        </td>
        <td>
          <Currency>{data.portfolioValue}</Currency>
        </td>
      </tr>
    );
  };

  render() {
    return (
      <div className="table-container">
        <table className="table is-narrow is-striped is-hoverable cashflowTable is-size-7 is-fullwidth is-bordered">
          <thead>
            <tr className="no-bottom">
              <th />
              <th className="has-text-centered">Jahr</th>
              <th className="has-text-centered">Monat</th>
              <th
                colSpan="4"
                className="has-text-centered border-right"
                style={{ borderRight: '2px' }}
              >
                Kauf
              </th>
              <th colSpan="4" className="has-text-centered">
                Miete
              </th>
              <th className="has-text-centered">Dif&shy;fe&shy;renz</th>
              <th className="has-text-centered">Wert Immobilie</th>
              <th className="has-text-centered">Wert Portfolio</th>
            </tr>
            <tr>
              <th />
              <th />
              <th />
              <th className="has-text-centered">Kredit&shy;rate</th>
              <th className="has-text-centered">Betriebs&shy;kosten</th>
              <th className="has-text-centered">Instand&shy;haltung</th>
              <th className="has-text-centered">Summe</th>
              <th className="has-text-centered">Miete</th>
              <th className="has-text-centered">Betriebs&shy;kosten</th>
              <th className="has-text-centered">Instand&shy;haltung</th>
              <th className="has-text-centered">Summe</th>
              <th />
              <th />
              <th />
            </tr>
          </thead>
          <tbody>{this.createTableContent()}</tbody>
        </table>
      </div>
    );
  }
}
