import React from 'react';
import NumberFormat from 'react-number-format';

const FormNumberInput = ({
  id,
  onChange,
  decimalScale = 2,
  allowNegative = false,
  readOnly = false,
  help,
  ...rest
}) => {
  const className = readOnly ? 'input is-static' : 'input';
  const handleFocus = event => {
    event.target.setSelectionRange(0, 9999);
  };

  const control = (
    <div className="control">
      <NumberFormat
        {...{ readOnly, decimalScale, allowNegative, className }}
        {...rest}
        onValueChange={values => {
          onChange(id, Number(values.value));
        }}
        thousandSeparator={true}
        fixedDecimalScale={true}
        onFocus={handleFocus}
      />
    </div>
  );

  const controlWithHelp = (
    <div className="field">
      {control}
      <p className="help">{help}</p>
    </div>
  );
  return (
    <div className="field number-field">{help ? controlWithHelp : control}</div>
  );
};
export default FormNumberInput;
