import React from 'react';
import FormInputLine from './FormInputLine';
import FormNumberInput from './FormNumberInput';
import CashflowTable from './CashflowTable';
import ResultSummary from './ResultSummary';
import ExplanationSection from './ExplanationSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';

const Result = props => {
  if (process.env.NODE_ENV !== 'production') {
    console.log(props.paymentPlan);
  }
  const paymentPlan = props.paymentPlan;
  const endwertImmobilie =
    paymentPlan.payments[paymentPlan.payments.length - 1].buy.totalValue;
  const portfolioValue =
    paymentPlan.payments[paymentPlan.payments.length - 1].portfolioValue;
  const depositSum =
    paymentPlan.payments[paymentPlan.payments.length - 1].depositSum;
  const portfolioValueAfterTax =
    depositSum + (portfolioValue - depositSum) * 0.725;

  return (
    <div className="results">
      <h3 className="title">Ergebnis</h3>

      <ResultSummary
        endwertImmobilie={endwertImmobilie}
        portfolioValueAfterTax={portfolioValueAfterTax}
      />

      <div className="columns show-narrow">
        <div className="column">
          <h5 className="title is-5 is-marginless has-text-centered">Kauf</h5>
          <FormInputLine label="Kredith&ouml;he" href="#erklaerung-kredithoehe">
            <FormNumberInput
              value={props.kreditbetrag}
              suffix={' €'}
              readOnly
              onChange={() => {}} // is needed, otherwise crash
              allowNegative
              tabIndex="-1"
              aria-label="Kredithöhe"
            />
          </FormInputLine>
          <FormInputLine label="Kreditrate" href="#erklaerung-kreditrate">
            <FormNumberInput
              value={props.kreditrate}
              suffix={' €'}
              readOnly
              onChange={() => {}} // is needed, otherwise crash
              allowNegative
              tabIndex="-1"
              aria-label="Kreditrate"
            />
          </FormInputLine>
          <FormInputLine
            label="Endwert Immobilie"
            href="#erklaerung-endwert-immobilie"
          >
            <FormNumberInput
              value={endwertImmobilie}
              suffix={' €'}
              readOnly
              onChange={() => {}} // is needed, otherwise crash
              tabIndex="-1"
              aria-label="Endwert Immobilie"
            />
          </FormInputLine>
        </div>
        <hr className="is-marginless" />
        <div className="column rentResultsColumn">
          <h5 className="title is-5 is-marginless has-text-centered">Miete</h5>
          <FormInputLine
            label="Endwert Portfolio (vor KESt)"
            href="#erklaerung-endwert-portfolio"
          >
            <FormNumberInput
              value={portfolioValue}
              suffix={' €'}
              readOnly
              onChange={() => {}} // is needed, otherwise crash
              tabIndex="-1"
              aria-label="Endwert Portfolio (vor KESt)"
            />
          </FormInputLine>
          <FormInputLine
            label="Endwert Portfolio (nach KESt)"
            href="#erklaerung-endwert-portfolio"
          >
            <FormNumberInput
              value={portfolioValueAfterTax}
              suffix={' €'}
              readOnly
              onChange={() => {}} // is needed, otherwise crash
              tabIndex="-1"
              aria-label="Endwert Portfolio (nach KESt)"
            />
          </FormInputLine>
        </div>
      </div>

      <div className="columns hide-narrow">
        <div className="column no-vertical-padding">
          <h5 className="title is-5 is-marginless has-text-centered">Kauf</h5>
        </div>
        <div className="column rentResultsColumn no-vertical-padding">
          <h5 className="title is-5 is-marginless has-text-centered">Miete</h5>
        </div>
      </div>

      <div className="columns hide-narrow">
        <div className="column no-vertical-padding">
          <FormInputLine label="Kredith&ouml;he" href="#erklaerung-kredithoehe">
            <FormNumberInput
              value={props.kreditbetrag}
              suffix={' €'}
              readOnly
              onChange={() => {}} // is needed, otherwise crash
              allowNegative
              tabIndex="-1"
              aria-label="Kredithöhe"
            />
          </FormInputLine>
        </div>
        <div className="column no-vertical-padding" />
      </div>
      <div className="columns hide-narrow">
        <div className="column no-vertical-padding">
          <FormInputLine label="Kreditrate" href="#erklaerung-kreditrate">
            <FormNumberInput
              value={props.kreditrate}
              suffix={' €'}
              readOnly
              onChange={() => {}} // is needed, otherwise crash
              allowNegative
              tabIndex="-1"
              aria-label="Kreditrate"
            />
          </FormInputLine>
        </div>
        <div className="column rentResultsColumn no-vertical-padding">
          <FormInputLine
            label="Endwert Portfolio (vor KESt)"
            href="#erklaerung-endwert-portfolio"
          >
            <FormNumberInput
              value={portfolioValue}
              suffix={' €'}
              readOnly
              onChange={() => {}} // is needed, otherwise crash
              tabIndex="-1"
              aria-label="Endwert Portfolio (vor KESt)"
            />
          </FormInputLine>
        </div>
      </div>

      <div className="columns hide-narrow">
        <div className="column no-vertical-padding">
          <FormInputLine
            label="Endwert Immobilie"
            href="#erklaerung-endwert-immobilie"
          >
            <FormNumberInput
              value={endwertImmobilie}
              suffix={' €'}
              readOnly
              onChange={() => {}} // is needed, otherwise crash
              tabIndex="-1"
              aria-label="Endwert Immobilie"
            />
          </FormInputLine>
        </div>
        <div className="column no-vertical-padding">
          <FormInputLine
            label="Endwert Portfolio (nach KESt)"
            href="#erklaerung-endwert-portfolio"
          >
            <FormNumberInput
              value={portfolioValueAfterTax}
              suffix={' €'}
              readOnly
              onChange={() => {}} // is needed, otherwise crash
              tabIndex="-1"
              aria-label="Endwert Portfolio (nach KESt)"
            />
          </FormInputLine>
        </div>
      </div>

      <div className="box has-text-centered content">
        <p>
          Das Ergebnis des Rechners soll nur eine Orientierungshilfe sein. Bitte
          verwende diesen Rechner nicht als alleinige Entscheidungsgrundlage für
          eine schwerwiegende finanzielle Entscheidung. Ich bin weder Finanz-
          noch Steuerberater. Außerdem kann es sein, dass die Berechnung
          fehlerhaft ist oder dass ich mich irgendwo geirrt habe. Falls das so
          ist, dann gib mir bitte Bescheid!
        </p>
        <p>
          Du kannst mich gerne auch mit Feedback oder anderen Kommentaren
          kontaktieren:
        </p>
        <p>
          via{' '}
          <a href="mailto:wolfgang@kaufOderMiete.at">
            <FontAwesomeIcon icon={faEnvelope} size="lg" /> Email
          </a>{' '}
          oder auf{' '}
          <a href="https://www.twitter.com/woolfib">
            <FontAwesomeIcon icon={faTwitter} size="lg" /> Twitter
          </a>
          .
        </p>
      </div>

      <ExplanationSection />

      <div>
        <h3 className="title">Details / Rechengang</h3>
        <p>
          Für jeden Monat werden die Ausgaben bei Kauf und bei Miete berechnet
          und miteinander verglichen. Die Differenz wird investiert (bzw. in
          Ausnahmefällen entnommen) und mit dem angenommenen
          Kapitalertragszinssatz verzinst.
        </p>
        <br />
        {/* key prop creates new CashflowTable when props.laufzeit changes */}
        <CashflowTable data={props.paymentPlan} key={props.laufzeit} />
      </div>
    </div>
  );
};

export default Result;
