import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import reducer from './reducers';

const initialState = {
  kauf: {
    kaufpreis: 300000,
    betriebskostenBruttoKauf: 165,
    nebenkostenProzentsatz: 10,
    eigenmittel: 90000,
    kreditzinssatzEffPA: 3,
    laufzeit: 25,
    wertsteigerung: 4,
    instandhaltungskostenKauf: 0.4
  },
  miete: {
    mieteBrutto: 690,
    betriebskostenBruttoMiete: 110,
    maklergebuehrenMiete: 2,
    kaution: 3,
    vertragsvergebuehrung: 0,
    mietsteigerung: 2.5,
    instandhaltungskostenMiete: 0.2,
    kapitalertrag: 9
  }
};

let middlewares = [thunkMiddleware];

if (process.env.NODE_ENV !== 'production') {
  const createLogger = require('redux-logger').createLogger;
  const loggerMiddleware = createLogger();
  middlewares.push(loggerMiddleware);
}

export default function configureStore() {
  return createStore(reducer, initialState, applyMiddleware(...middlewares));
}
