import React, { useState } from 'react';
import FormInputLine from './FormInputLine';
import FormNumberInput from './FormNumberInput';

const KaufInputForm = props => {
  const [kauf, setKauf] = useState(props.initialKauf);

  const handleChange = (id, value) => {
    setKauf({ ...kauf, [id]: value });
    props.onChange({ ...kauf, [id]: value });
  };

  const handleBetriebskostenNettoChange = (id, value) => {
    handleChange('betriebskostenBruttoKauf', value * 1.1);
  };

  const nebenkostenGesamt =
    kauf.kaufpreis * (kauf.nebenkostenProzentsatz / 100);

  const tabIndex = 4;

  return (
    <div className="column">
      <div className="field is-horizontal flex">
        <div className="field-label is-normal">
          <label className="title">Kauf</label>
        </div>
        <div className="field-body" />
      </div>
      <FormInputLine label="Kauf&shy;preis" href="#erklaerung-kaufpreis">
        <FormNumberInput
          id="kaufpreis"
          value={kauf.kaufpreis}
          onChange={handleChange}
          suffix=" €"
          tabIndex={tabIndex}
          aria-label="Kaufpreis"
        />
      </FormInputLine>
      <FormInputLine
        label="Betriebs&shy;kosten"
        href="#erklaerung-betriebskosten-kauf"
      >
        <FormNumberInput
          id="betriebskostenNettoKauf"
          value={kauf.betriebskostenBruttoKauf / 1.1}
          suffix=" €"
          onChange={handleBetriebskostenNettoChange}
          help="netto"
          tabIndex={tabIndex + 1}
          aria-label="Betriebskosten netto"
        />
        <FormNumberInput
          id="betriebskostenBruttoKauf"
          value={kauf.betriebskostenBruttoKauf}
          suffix=" €"
          onChange={handleChange}
          help="brutto"
          tabIndex={tabIndex + 2}
          aria-label="Betriebskosten brutto"
        />
      </FormInputLine>
      <FormInputLine label="Neben&shy;kosten" href="#erklaerung-nebenkosten">
        <FormNumberInput
          id="nebenkostenProzentsatz"
          value={kauf.nebenkostenProzentsatz}
          suffix=" %"
          onChange={handleChange}
          tabIndex={tabIndex + 3}
          aria-label="Nebenkosten in %"
        />
        <FormNumberInput
          id="nebenkostenGesamt"
          value={nebenkostenGesamt}
          suffix=" €"
          readOnly
          tabIndex="-1"
          aria-label="Nebenkosten absolut"
          onChange={() => {}} // is needed, otherwise crash
        />
      </FormInputLine>
      <FormInputLine label="Eigen&shy;mittel" href="#erklaerung-eigenmittel">
        <FormNumberInput
          id="eigenmittel"
          value={kauf.eigenmittel}
          suffix=" €"
          onChange={handleChange}
          tabIndex={tabIndex + 4}
          aria-label="Eigenmittel"
        />
      </FormInputLine>
      <FormInputLine
        label="Zinssatz des Kredits"
        href="#erklaerung-kreditzinssatz"
      >
        <FormNumberInput
          id="kreditzinssatzEffPA"
          value={kauf.kreditzinssatzEffPA}
          suffix=" %"
          onChange={handleChange}
          help="effektiv pro Jahr"
          tabIndex={tabIndex + 5}
          aria-label="Zinssatz des Kredits (effektiv pro Jahr)"
        />
      </FormInputLine>
      <FormInputLine label="Laufzeit" href="#erklaerung-laufzeit">
        <FormNumberInput
          id="laufzeit"
          value={kauf.laufzeit}
          suffix={' Jahre'}
          decimalScale={0}
          onChange={handleChange}
          isAllowed={values => {
            const { floatValue } = values;
            return floatValue >= 1;
          }}
          tabIndex={tabIndex + 6}
          aria-label="Laufzeit"
        />
      </FormInputLine>
      <FormInputLine
        label="In&shy;stand&shy;hal&shy;tungs&shy;kos&shy;ten"
        href="#erklaerung-instandhaltungskosten-kauf"
      >
        <FormNumberInput
          id="instandhaltungskostenKauf"
          value={kauf.instandhaltungskostenKauf}
          suffix=" %"
          onChange={handleChange}
          help="pro Jahr in % des Immobilienwerts"
          tabIndex={tabIndex + 7}
          aria-label="Instandhaltungskosten (pro Jahr in % des Immobilienwerts)"
        />
      </FormInputLine>
      <FormInputLine
        label="Wert&shy;steigerung"
        href="#erklaerung-wertsteigerung"
      >
        <FormNumberInput
          id="wertsteigerung"
          value={kauf.wertsteigerung}
          suffix=" %"
          onChange={handleChange}
          help="pro Jahr"
          allowNegative
          tabIndex={tabIndex + 8}
          aria-label="Wertsteigerung (pro Jahr in %)"
        />
      </FormInputLine>
    </div>
  );
};

export default KaufInputForm;
