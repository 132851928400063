import React from 'react';

const FormInputLine = props => {
  const anchor = props.href;
  return (
    <div className="field is-horizontal flex">
      <div className="field-label is-normal label">
        {anchor ? <a href={anchor}>{props.label}</a> : props.label}
      </div>
      <div className="field-body">{props.children}</div>
    </div>
  );
};

export default FormInputLine;
