import React, { useState } from 'react';
import FormInputLine from './FormInputLine';
import FormNumberInput from './FormNumberInput';

const MieteInputForm = props => {
  const [miete, setMiete] = useState(props.initialMiete);

  const handleChange = (id, value) => {
    setMiete({ ...miete, [id]: value });
    props.onChange({ ...miete, [id]: value });
  };

  const handleMieteNettoChange = (id, value) => {
    handleChange('mieteBrutto', value * 1.1);
  };

  const handleBetriebskostenNettoChange = (id, value) => {
    handleChange('betriebskostenBruttoMiete', value * 1.1);
  };

  const maklergebuehrenMieteEUR =
    (miete.maklergebuehrenMiete *
      1.2 *
      (miete.mieteBrutto + miete.betriebskostenBruttoMiete)) /
    1.1;
  const kautionEUR =
    miete.kaution * (miete.mieteBrutto + miete.betriebskostenBruttoMiete);

  const tabIndex = 13;

  return (
    <div className="column">
      <div className="field is-horizontal flex">
        <div className="field-label is-normal">
          <label className="title">Miete</label>
        </div>
        <div className="field-body" />
      </div>
      <FormInputLine label="Miete" href="#erklaerung-miete">
        <FormNumberInput
          id="mieteNetto"
          value={miete.mieteBrutto / 1.1}
          suffix=" €"
          onChange={handleMieteNettoChange}
          help="netto"
          tabIndex={tabIndex}
          aria-label="Miete netto"
        />
        <FormNumberInput
          id="mieteBrutto"
          value={miete.mieteBrutto}
          suffix=" €"
          onChange={handleChange}
          help="brutto"
          tabIndex={tabIndex + 1}
          aria-label="Miete brutto"
        />
      </FormInputLine>
      <FormInputLine
        label="Betriebs&shy;kosten"
        href="#erklaerung-betriebskosten-miete"
      >
        <FormNumberInput
          id="betriebskostenNetto"
          value={miete.betriebskostenBruttoMiete / 1.1}
          suffix=" €"
          onChange={handleBetriebskostenNettoChange}
          help="netto"
          tabIndex={tabIndex + 2}
          aria-label="Betriebskosten netto"
        />
        <FormNumberInput
          id="betriebskostenBruttoMiete"
          value={miete.betriebskostenBruttoMiete}
          suffix=" €"
          onChange={handleChange}
          help="brutto"
          tabIndex={tabIndex + 3}
          aria-label="Betriebskosten brutto"
        />
      </FormInputLine>
      <FormInputLine
        label="Makler&shy;provision"
        href="#erklaerung-maklerprovision"
      >
        <FormNumberInput
          id="maklergebuehrenMiete"
          value={miete.maklergebuehrenMiete}
          suffix=" MM"
          decimalScale={1}
          onChange={handleChange}
          help="in Monatsmieten"
          tabIndex={tabIndex + 4}
          aria-label="Maklerprovision (in Monatsmieten)"
        />
        <FormNumberInput
          id="maklergebuehrenMieteEUR"
          value={maklergebuehrenMieteEUR}
          suffix=" €"
          readOnly
          onChange={() => {}} // is needed, otherwise crash
          tabIndex="-1"
          aria-label="Maklerprovision (in €)"
        />
      </FormInputLine>
      <FormInputLine label="Kaution" href="#erklaerung-kaution">
        <FormNumberInput
          id="kaution"
          value={miete.kaution}
          suffix=" MM"
          decimalScale={1}
          onChange={handleChange}
          help="in Monatsmieten"
          tabIndex={tabIndex + 5}
          aria-label="Kaution (in Monatsmieten)"
        />
        <FormNumberInput
          id="kautionEUR"
          value={kautionEUR}
          suffix=" €"
          readOnly
          onChange={() => {}} // is needed, otherwise crash
          tabIndex="-1"
          aria-label="Kaution (in €)"
        />
      </FormInputLine>
      <FormInputLine label="Miet&shy;erhöhung" href="#erklaerung-mieterhoehung">
        <FormNumberInput
          id="mietsteigerung"
          value={miete.mietsteigerung}
          suffix=" %"
          onChange={handleChange}
          help="pro Jahr, &uuml;blicherweise Inflationsrate"
          tabIndex={tabIndex + 6}
          aria-label="Mieterhöhung (in % pro Jahr)"
        />
      </FormInputLine>
      <FormInputLine
        label="In&shy;stand&shy;hal&shy;tungs&shy;kos&shy;ten"
        href="#erklaerung-instandhaltungskosten-miete"
      >
        <FormNumberInput
          id="instandhaltungskostenMiete"
          value={miete.instandhaltungskostenMiete}
          suffix=" %"
          onChange={handleChange}
          help="pro Jahr in % des Immobilienwerts"
          tabIndex={tabIndex + 7}
          aria-label="Instandhaltungskosten (pro Jahr in des Immobilienwerts)"
        />
      </FormInputLine>
      <FormInputLine
        label="Kapital&shy;ertrag"
        href="#erklaerung-kapitalertrag"
      >
        <FormNumberInput
          id="kapitalertrag"
          value={miete.kapitalertrag}
          suffix=" %"
          onChange={handleChange}
          help="pro Jahr"
          allowNegative
          tabIndex={tabIndex + 8}
          aria-label="Kapitalertrag (in % pro Jahr)"
        />
      </FormInputLine>
    </div>
  );
};

export default MieteInputForm;
