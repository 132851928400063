import { connect } from 'react-redux';
import Result from './Result';

const calculateKreditBetrag = kauf => {
  const nk = kauf.kaufpreis * (kauf.nebenkostenProzentsatz / 100);
  return Math.max(kauf.kaufpreis + nk - kauf.eigenmittel, 0);
};

const calculateKreditrate = (kauf, kreditbetrag) => {
  if (kreditbetrag <= 0) return 0;
  const ratenAnzahl = kauf.laufzeit * 12;
  if (kauf.kreditzinssatzEffPA === 0) {
    return kreditbetrag / ratenAnzahl;
  }
  const effMonthlyInterest =
    Math.pow(1 + kauf.kreditzinssatzEffPA / 100.0, 1 / 12) - 1;
  const interestMultiple = Math.pow(effMonthlyInterest + 1, ratenAnzahl);
  const rate =
    (kreditbetrag * interestMultiple * effMonthlyInterest) /
    (interestMultiple - 1);
  return rate;
};

const generatePaymentPlan = (kauf, miete, loanPayment) => {
  const payments = [];
  const wertsteigerungMonatlich = Math.pow(
    1 + kauf.wertsteigerung / 100,
    1 / 12
  );
  const instandhaltungskostenRateMonatlichKauf =
    Math.pow(1 + kauf.instandhaltungskostenKauf / 100, 1 / 12) - 1;
  const instandhaltungskostenRateMonatlichMiete =
    Math.pow(1 + miete.instandhaltungskostenMiete / 100, 1 / 12) - 1;
  let totalValue = kauf.kaufpreis;
  let rent = miete.mieteBrutto;
  const maklergebuehrenMieteEUR =
    (miete.maklergebuehrenMiete *
      1.2 *
      (miete.mieteBrutto + miete.betriebskostenBruttoMiete)) /
    1.1;
  const kautionEUR =
    miete.kaution * (miete.mieteBrutto + miete.betriebskostenBruttoMiete);

  const transactionCosts = kauf.kaufpreis * (kauf.nebenkostenProzentsatz / 100);
  let betriebskostenMiete = miete.betriebskostenBruttoMiete;
  let betriebskostenKauf = kauf.betriebskostenBruttoKauf;

  payments.push({
    period: 0,
    buy: {
      totalValue,
      downPayment: Math.min(
        kauf.eigenmittel - transactionCosts,
        kauf.kaufpreis
      ),
      transactionCosts,
      totalPayment: Math.min(
        kauf.eigenmittel,
        kauf.kaufpreis + transactionCosts
      )
    },
    rent: {
      makler: maklergebuehrenMieteEUR,
      kaution: kautionEUR,
      totalPayment: maklergebuehrenMieteEUR + kautionEUR
    }
  });

  for (let i = 1; i <= kauf.laufzeit * 12; i++) {
    totalValue *= wertsteigerungMonatlich;
    const repairCostsBuy = totalValue * instandhaltungskostenRateMonatlichKauf;
    const repairCostsRent =
      totalValue * instandhaltungskostenRateMonatlichMiete;
    const totalPayment = loanPayment + repairCostsBuy + betriebskostenKauf;
    payments.push({
      period: i,
      buy: {
        totalValue,
        loanPayment,
        betriebskostenKauf,
        repairCosts: repairCostsBuy,
        totalPayment
      },
      rent: {
        rent,
        betriebskostenMiete,
        repairCosts: repairCostsRent,
        totalPayment: rent + repairCostsRent + betriebskostenMiete
      }
    });
    if (i % 12 === 0) {
      rent *= 1 + miete.mietsteigerung / 100;
      betriebskostenKauf *= 1 + miete.mietsteigerung / 100;
      betriebskostenMiete *= 1 + miete.mietsteigerung / 100;
    }
  }

  const verzinsteKaution =
    kautionEUR * Math.pow(1 + miete.mietsteigerung / 100, kauf.laufzeit);
  payments[kauf.laufzeit * 12].rent.kaution = -verzinsteKaution;
  payments[kauf.laufzeit * 12].rent.totalPayment -= verzinsteKaution;

  calculateRentPortfolioValue(payments, miete);
  return { wertsteigerungMonatlich, payments };
};

const calculateRentPortfolioValue = (payments, miete) => {
  const returnMonthly = Math.pow(1 + miete.kapitalertrag / 100, 1 / 12);
  let portfolioValue = 0;
  let depositSum = 0;
  payments.forEach(period => {
    period.difference = period.buy.totalPayment - period.rent.totalPayment;
    depositSum += period.difference;
    period.depositSum = depositSum;
    portfolioValue = portfolioValue * returnMonthly + period.difference;
    period.portfolioValue = portfolioValue;
  });
};

const mapStateToProps = state => {
  const kreditbetrag = calculateKreditBetrag(state.kauf);
  const loanPayment = calculateKreditrate(state.kauf, kreditbetrag);
  return {
    kreditbetrag,
    laufzeit: state.kauf.laufzeit,
    kreditrate: loanPayment,
    paymentPlan: generatePaymentPlan(state.kauf, state.miete, loanPayment)
  };
};

const ResultContainer = connect(mapStateToProps)(Result);

export default ResultContainer;
