import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './store';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import App from './App';

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop>
      <Provider store={configureStore()}>
        <App />
      </Provider>
    </ScrollToTop>
  </BrowserRouter>,
  document.getElementById('root')
);
