import React from 'react';
import avatar1 from '../assets/avatar1.png';
import avatar2 from '../assets/avatar2.png';
import avatar3 from '../assets/avatar3.png';

const Introduction = props => {
  return (
    <React.Fragment>
      <div className="columns is-desktop">
        <div className="column">
          <div className="box testimonial-box height-100-pct">
            <div className="columns is-gapless is-mobile">
              <div className="column is-narrow">
                <figure className="image is-96x96">
                  <img src={avatar1} alt="Avatar 1" />
                </figure>
              </div>
              <div className="column">
                <p>Miete zu zahlen ist rausgeworfenes Geld!</p>
              </div>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="box testimonial-box height-100-pct">
            <div className="columns is-gapless is-mobile">
              <div className="column is-narrow">
                <figure className="image is-96x96">
                  <img src={avatar2} alt="Avatar 2" />
                </figure>
              </div>
              <div className="column">
                <p>
                  Wenn man es sich leisten kann, ist es immer besser eine
                  Immobilie zu kaufen als sie zu mieten.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="column">
          <div className="box testimonial-box height-100-pct">
            <div className="columns is-gapless is-mobile">
              <div className="column is-narrow">
                <figure className="image is-96x96">
                  <img src={avatar3} alt="Avatar 3" />
                </figure>
              </div>
              <div className="column">
                <p>
                  Als Käufer besitzt man irgendwann die Immobilie. Als Mieter
                  zahlt man zwar laufend, besitzt aber am Ende nichts!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="notification is-info has-text-centered is-size-5">
        Derartige Aussagen hört man zwar immer wieder, sie stimmen aber so
        sicher nicht!
        <br />
        <strong>
          Manchmal ist Kauf, manchmal ist aber auch Miete die bessere Option!
        </strong>
        <br />
        Es kommt auf das Zusammenspiel vieler Faktoren an.
      </div>
      <div className="content">
        <p>
          Die Ausgaben bei Miete sind nämlich (zumindest fast immer) geringer
          als die Ausgaben beim Kauf, und diese Differenz muss unbedingt im
          Vergleich berücksichtigt werden!
        </p>
        <p>
          Der Rechner auf dieser Seite soll dabei helfen die wahren Kosten von
          Kauf und Miete einer Wohnung oder eines Hauses zu vergleichen.
        </p>
        <h3 className="title">Wie funktioniert der Vergleich?</h3>
        <p>
          Als Käufer besitzt man nach Rückzahlung des Kredits die Immobilie. Für
          den Käufer wählen wir daher den Wert der Immobilie zu diesem Zeitpunkt
          als Vergleichsgröße.
        </p>
        <p>
          Als Mieter besitzt man keine Immobilie, hat jedoch im Zeitverlauf
          andere und meist geringere Ausgaben als ein Käufer. Um Kauf und Miete
          vergleichbar zu machen, nehmen wir an, dass Mieter die Differenz der
          Ausgaben im Vergleich zum Kauf auf dem Kapitalmarkt anlegen. Dadurch
          machen wir die Ausgaben von Käufer und Mieter exakt gleich hoch.
        </p>
        <p>
          Dann können wir am Ende der Laufzeit des Kredits den Wert der
          Immobilie mit der Höhe des vom Mieter angesparten Vermögens
          vergleichen. So lässt sich beurteilen, ob Kauf oder Miete unter den
          getroffenen Annahmen die bessere Entscheidung ist.
        </p>
      </div>
    </React.Fragment>
  );
};

export default Introduction;
