import { connect } from 'react-redux';
import KaufInputForm from './KaufInputForm';
import { changeKauf } from '../actions';
import { debounce } from 'debounce';

const mapStateToProps = state => {
  return {
    initialKauf: state.kauf
  };
};

const mapDispatchToProps = dispatch => {
  const composed = kauf => {
    dispatch(changeKauf(kauf));
  };

  return {
    onChange: debounce(composed, 500)
  };
};

const KaufInputContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(KaufInputForm);

export default KaufInputContainer;
