import React from 'react';
import './App.scss';
import MainPage from './components/MainPage';
import Impressum from './components/Impressum';
import Footer from './components/Footer';
import { Switch, Route, Redirect } from 'react-router-dom';

const App = () => {
  return (
    <React.Fragment>
      <section className="section">
        <div className="container">
          <Switch>
            <Route exact path="/" component={MainPage} />
            <Route exact path="/impressum" component={Impressum} />
            <Redirect to="/" />
          </Switch>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default App;
