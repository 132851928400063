import React from 'react';
import House from '../assets/real-estate-house.svg';
import ShareButtons from './ShareButtons';

const Title = props => {
  return (
    <React.Fragment>
      <div className="columns is-mobile">
        <div className="column is-narrow hide-narrow">
          <img src={House} alt="Logo" width={150} />
        </div>
        <div className="column">
          <h1 className="title is-size-1 has-text-weight-bold">
            Immobilie Kaufen oder Mieten?
          </h1>
          <h2 className="margin-bottom-sm subtitle is-size-4 has-text-weight-medium ">
            Welche der beiden Optionen ist finanziell gesehen die bessere
            Entscheidung?
          </h2>
          <ShareButtons tabIndex={1} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Title;
