import React from 'react';

const ExplanationSection = props => {
  return (
    <React.Fragment>
      <h3 className="title">Begriffserklärungen</h3>
      <h4 className="title is-4">Kauf</h4>

      <div className="columns">
        <div
          className="column is-2 has-text-weight-semibold explanation-header"
          id="erklaerung-kaufpreis"
        >
          Kaufpreis
        </div>
        <div className="column">
          <p>
            Der Kaufpreis ist der Preis der Immobilie. Bei Preisverhandlung geht
            es um die Höhe dieses Betrags. Insgesamt zu zahlen sind jedoch
            zusätzlich auch noch die Nebenkosten.
          </p>
        </div>
      </div>

      <div className="columns">
        <div
          className="column is-2 has-text-weight-semibold explanation-header"
          id="erklaerung-betriebskosten-kauf"
        >
          Betriebskosten
        </div>
        <div className="column">
          <p>
            Zu den Betriebskosten, die sowohl Mieter als auch Eigentümer zahlen
            müssen, gehören laufende Zahlungen für Wasser, Müllabfuhr,
            Hausbetreuung, etc..
          </p>
          <p>
            Eigentümer müssen alle Aufwendungen für die Immobilie anteilig
            zahlen. Zusätzlich zu den Kosten, die auch Mieter zu zahlen haben,
            fallen für Eigentümer noch Kosten für Erhaltungs- und
            Verbesserungsarbeiten am Gemeinschaftseigentum, Rücklagen, etc. an
            (siehe{' '}
            <a href="https://www.help.gv.at/Portal.Node/hlpd/public/content/21/Seite.210160.html">
              Laufende Aufwendungen des Wohnungseigenümers auf help.gv.at
            </a>{' '}
            und{' '}
            <a href="https://de.wikipedia.org/wiki/Peterssche_Formel">
              Peterssche Formel zur Berechnung der Instandhaltungsrücklage
            </a>
            ).
          </p>
        </div>
      </div>

      <div className="columns">
        <div
          className="column is-2 has-text-weight-semibold explanation-header"
          id="erklaerung-nebenkosten"
        >
          Nebenkosten
        </div>
        <div className="column">
          <p>
            Neben dem eigentlichen Kaufpreis fallen beim Kauf einer Immobilie
            noch einige weitere Kosten an. Dazu gehören u.a. Maklerprovision,
            Grunderwerbsteuer, Grundbuchsgebühr und Notariatskosten. Die Höhe
            mancher dieser Positionen ist verhandelbar. Üblicherweise liegen die
            Nebenkosten in Summe bei ca. 10% des Kaufpreises.
          </p>
        </div>
      </div>

      <div className="columns">
        <div
          className="column is-2 has-text-weight-semibold explanation-header"
          id="erklaerung-eigenmittel"
        >
          Eigenmittel
        </div>
        <div className="column">
          <p>
            Die Höhe der eigenen Ersparnisse, die für den Kauf der Immobilie
            ausgegeben werden. Um einen Kredit zu bekommen, verlangen Banken
            meistens einen Mindestanteil der Eigenmittel am Kaufpreis. Diese
            Mindesteigenkapitalquote liegt oft bei ca. 20%.
          </p>
        </div>
      </div>

      <div className="columns">
        <div
          className="column is-2 has-text-weight-semibold explanation-header"
          id="erklaerung-kreditzinssatz"
        >
          Zinssatz des Kredits
        </div>
        <div className="column">
          <p>
            Effektivzinssatz des Kredits über die gesamte Laufzeit. Banken
            werben gerne mit einem niedrigen Zinssatz. Dabei handelt es sich
            meist um den Nominalzinssatz, der jedoch nicht alle Kosten eines
            Kredits berücksichtigt. Im hier verwendeten Effektivzinssatz sind
            bereits alle Kreditkosten berücksichtigt.
          </p>
          <p>
            Wichtig ist es zu bedenken, dass sich der Zinssatz während der
            Laufzeit des Kredits stark ändern kann. Da wir derzeit historisch
            gesehen sehr niedrige Zinsen haben, ist es durchaus möglich, dass
            die Zinsen in Zukunft wieder stärker ansteigen könnten. Dadurch
            würde sich auch die Kreditrate erhöhen. Es ist daher wichtig, sich
            nur so hoch zu verschulden, dass man auch bei einem Zinsanstieg
            weiterhin die Raten zahlen kann.
          </p>
          <p>
            Man kann sich gegen steigende Zinsen jedoch auch absichern, indem
            man einen Kredit mit fixen Zinsen vereinbart. Dadurch ist der
            Zinssatz zwar momentan höher als bei einem Kredit mit variablem
            Zinssatz, man ist aber gegen steigende Zinsen in der Zukunft
            abgesichert. Meist gelten diese fixen Zinssätze aber nicht für die
            gesamte Laufzeit des Kredits, sodass man gegen Ende der Laufzeit
            erst wieder einem Zinssatzrisiko ausgesetzt ist.
          </p>
        </div>
      </div>

      <div className="columns">
        <div
          className="column is-2 has-text-weight-semibold explanation-header"
          id="erklaerung-laufzeit"
        >
          Laufzeit
        </div>
        <div className="column">
          <p>
            Der Zeitraum über den der Kredit abbezahlt werden soll. Es ist
            empfehlenswert darauf zu achten, dass zusätzlich zu den vereinbarten
            Ratenzahlungen auch weitere vorzeitige Rückzahlungen (ohne allzu
            hohe Gebühren) möglich sind. Sollte man später zusätzliches Geld zur
            Verfügung haben, kann so der Kredit früher zurückgezahlt werden.
          </p>
        </div>
      </div>

      <div className="columns">
        <div
          className="column is-2 has-text-weight-semibold explanation-header"
          id="erklaerung-instandhaltungskosten-kauf"
        >
          Instand&shy;haltungs&shy;kosten
        </div>
        <div className="column">
          <p>
            Dieser Wert beinhaltet die Instandhaltungs- und Reparaturkosten für
            die Immobilie im Eigentum. Die Kosten für die Instandhaltung von
            gemeinschaftlichem Eigentum sind schon in den Betriebskosten
            enthalten, hier geht es um die Instandhaltung des individuellen
            Eigentums.
          </p>
          <p>
            Die Instandhaltungskosten für Eigentümer sind höher als jene für
            Mieter, weil für gewisse Arbeiten (Heizung, Außenfenster,...) der
            Eigentümer und nicht der Mieter verantwortich ist.
          </p>
          <p>
            Die Instandhaltungskosten für Einfamilienhäuser sind üblicherweise
            höher als jene für Eigentumswohnungen.
          </p>
        </div>
      </div>

      <div className="columns">
        <div
          className="column is-2 has-text-weight-semibold explanation-header"
          id="erklaerung-wertsteigerung"
        >
          Wertsteigerung
        </div>
        <div className="column">
          <p>
            Die jährliche Wertsteigerung der Immobilie. Dieser Wert ist
            schwierig zu schätzen. Es kommt dabei auf Typ der Immobilie, Lage,
            den Immobilienmarkt im allgemeinen und zahlreiche weitere Faktoren
            an.
          </p>
          <p>
            Selbst verlässliche historische Daten sind hier nicht ganz einfach
            zu finden. Für Österreich gibt es dazu den{' '}
            <a href="https://www.oenb.at/Statistik/Standardisierte-Tabellen/Preise-Wettbewerbsfaehigkeit/Sektorale-Preisentwicklung/immobilienpreisindex.html">
              Wohnimmobilienpreisindex
            </a>
            , der von der Nationalbank veröffentlicht wird. Basierend darauf
            erscheint es vernünftig, eine Wertsteigerung im Bereich von 3.5% bis
            5.0% pro Jahr anzunehmen.
          </p>
        </div>
      </div>

      <div className="columns">
        <div
          className="column is-2 has-text-weight-semibold explanation-header"
          id="erklaerung-kredithoehe"
        >
          Kredithöhe / Kreditbetrag
        </div>
        <div className="column">
          <p>
            Die Höhe des Kredits, der aufgenommen werden muss um die Immobilie
            zu finanzieren. Errechnet sich aus Kaufpreis plus Nebenkosten
            abzüglich Eigenmittel.
          </p>
        </div>
      </div>

      <div className="columns">
        <div
          className="column is-2 has-text-weight-semibold explanation-header"
          id="erklaerung-kreditrate"
        >
          Kreditrate
        </div>
        <div className="column">
          <p>
            Die monatliche Rückzahlungsrate für den Kredit. Setzt sich zusammen
            aus einem Zinsanteil (um die angefallenen Zinsen zu bedienen) sowie
            einem Tilgungsanteil (zur Rückzahlung des Kreditbetrags).
          </p>
        </div>
      </div>

      <div className="columns">
        <div
          className="column is-2 has-text-weight-semibold explanation-header"
          id="erklaerung-endwert-immobilie"
        >
          Endwert der Immobilie
        </div>
        <div className="column">
          <p>
            Das ist der prognostizierte Wert der Immobilie zum Zeitpunkt der
            vollständigen Rückzahlung des Kredits. Dieser Wert errechnet sich
            aus dem Kaufpreis, der über die Laufzeit des Kredits mit der
            jährlichen Höhe der Wertsteigerung verzinst wird.
          </p>
        </div>
      </div>

      <h4 className="title is-4">Miete</h4>

      <div className="columns">
        <div
          className="column is-2 has-text-weight-semibold explanation-header"
          id="erklaerung-miete"
        >
          Miete
        </div>
        <div className="column">
          <p>
            Die Höhe der Miete ohne Betriebskosten. Der Umsatzsteuersatz bei der
            Vermietung zu Wohnzwecken beträgt in Österreich 10%.
          </p>
        </div>
      </div>

      <div className="columns">
        <div
          className="column is-2 has-text-weight-semibold explanation-header"
          id="erklaerung-betriebskosten-miete"
        >
          Betriebskosten
        </div>
        <div className="column">
          <p>
            Zu den Betriebskosten für Mieter zählen hauptsächlich laufende
            Zahlungen für Wasser, Müllabfuhr, Hausbetreuung, Kanalisation, etc..
            Auch hier gilt der ermäßigten Umsatzsteuersatz von 10%. Für
            Eigentümer kommen noch weitere Positionen zu den Betriebskosten
            hinzu.
          </p>
          <p>
            Meist werden die Betriebskosten als monatlicher Pauschalbetrag
            bezahlt. Die Differenz zu den tatsächlichen Kosten wird ein Mal
            jährlich ermittelt und abgerechnet.
          </p>
        </div>
      </div>

      <div className="columns">
        <div
          className="column is-2 has-text-weight-semibold explanation-header"
          id="erklaerung-maklerprovision"
        >
          Maklerprovision
        </div>
        <div className="column">
          <p>
            Die Maklerprovision darf maximal 2 Monatsmieten betragen. Unter
            bestimmten Umständen (z.B. befristetes Mietverhältnis auf maximal 3
            Jahre) kann dieses Maximum auch geringer sein. Zur Berechnung werden
            Netto-Miete und Netto-Betriebskosten herangezogen und mit 20%
            versteuert.
          </p>
        </div>
      </div>

      <div className="columns">
        <div
          className="column is-2 has-text-weight-semibold explanation-header"
          id="erklaerung-kaution"
        >
          Kaution
        </div>
        <div className="column">
          <p>
            Die Kaution dient als Sicherheit für den Vermieter sollte der Mieter
            die Immobilie beschädigen oder die Miete nicht zahlen. Ist dies
            nicht der Fall, so ist die Kaution beim Ausziehen dem Mieter wieder
            zurückzuzahlen.
          </p>
          <p>
            Üblich sind 3 Bruttomonatsmieten (Nettomiete plus Betriebskosten
            plus 10% Umsatzsteuer). Die Kaution muß vom Vermieter zinsbringend
            angelegt werden (üblicherweise als Sparbuch) und inklusive Zinsen
            zurückgezahlt werden.
          </p>
        </div>
      </div>

      <div className="columns">
        <div
          className="column is-2 has-text-weight-semibold explanation-header"
          id="erklaerung-mieterhoehung"
        >
          Mieterhöhung
        </div>
        <div className="column">
          <p>
            Um wie viel die Miete in einem laufenden Mietvertrag erhöht werden
            darf, ist gesetzlich geregelt. Die meisten Mietverträge enthalten
            eine sogennante Wertsicherungsklausel, die es dem Vermieter erlaubt
            die Miete im Einklang mit der Inflationsrate bzw. dem
            Verbraucherpreisindex zu erhöhen.
          </p>
          <p>
            Basierend auf der tatsächlichen Inflation der letzten Jahrzehnte
            dürfte ein Wert von 2.5% eine gute grobe Schätzung für die
            langfristige Inflationsrate sein.
          </p>
        </div>
      </div>

      <div className="columns">
        <div
          className="column is-2 has-text-weight-semibold explanation-header"
          id="erklaerung-instandhaltungskosten-miete"
        >
          Instand&shy;haltungs&shy;kosten
        </div>
        <div className="column">
          <p>
            Der Anteil der Instandhaltungkosten, die der Mieter zu zahlen hat.
            Die Instandhaltung von Gemeinschaftsanlagen gehört nicht dazu,
            genausowenig manche Anlagen im Inneren der Wohnung, für deren
            Erhaltung der Vermieter zuständig ist (z.B. Heizung, Außenfenster).
          </p>
        </div>
      </div>

      <div className="columns">
        <div
          className="column is-2 has-text-weight-semibold explanation-header"
          id="erklaerung-kapitalertrag"
        >
          Kapitalertrag
        </div>
        <div className="column">
          <p>
            Dieser Faktor hat große Auswirkungen auf das Endergebnis und ist
            zugleich schwierig zu schätzen. Je nachdem welche Anlageform
            (Sparbuch, Aktien, Anleihen, Fonds,...) gewählt wird, ist der zu
            erwartende Ertrag unterschiedlich hoch.
          </p>
          <p>
            Bei einem Immobilienkauf handelt es sich fast immer um ein
            langfristiges Investment. Dementsprechend sollte die Immobilie daher
            auch mit einem langfristigen Finanz-Investment verglichen werden.
            Und langfristig gesehen ist der Ertrag bei einem Investment in den
            Kapitalmarkt via Aktien/Fonds eindeutig am höchsten, auch wenn dafür
            kurz- und mittelfristig signifikante Wertschwankungen in Kauf
            genommen werden müssen.
          </p>
          <p>
            Am besten dürfte ein Investment in kostengünstige Fonds/ETFs sein,
            die den gesamten Aktienmarkt abbilden. Eine genaue Behandlung dieses
            Themas würde den Rahmen hier bei weitem sprengen.
          </p>
          <p>
            Historisch gesehen haben Aktienindizes langfristig einen
            durchschnittlichen Ertrag von etwa 9% pro Jahr, wobei die
            Schwankungen sogar über Jahrzehnte gesehen noch signifikant sein
            können.
          </p>
        </div>
      </div>

      <div className="columns">
        <div
          className="column is-2 has-text-weight-semibold explanation-header"
          id="erklaerung-endwert-portfolio"
        >
          Endwert des Portfolios
        </div>
        <div className="column">
          <p>
            Das ist der prognostizierte Wert des Finanzvermögens im
            Miet-Szenario, zu dem Zeitpunkt zu dem der Kredit im Kauf-Szenario
            abbezahlt wäre. Für die erzielten Gewinne aus dem finanziellen
            Investment gilt eine Kapitalertragsteuer(KESt) von 27.5% (bezieht
            sich nur auf die Gewinne, nicht das investierte Kapital selbst).
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ExplanationSection;
