import React from 'react';
import KaufInputContainer from './KaufInputContainer';
import MieteInputContainer from './MieteInputContainer';
import ResultContainer from './ResultContainer';
import Title from './Title';
import Introduction from './Introduction';

const MainPage = () => {
  return (
    <React.Fragment>
      <Title />
      <Introduction />
      <hr className="hr" />
      <div className="columns">
        <KaufInputContainer />
        <div className="is-divider-vertical padding-20" />
        <MieteInputContainer />
      </div>
      <ResultContainer />
    </React.Fragment>
  );
};

export default MainPage;
