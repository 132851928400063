export const CHANGE_KAUF = 'CHANGE_KAUF';
export function changeKauf(kauf) {
  return {
    type: CHANGE_KAUF,
    kauf
  };
}

export const CHANGE_MIETE = 'CHANGE_MIETE';
export function changeMiete(miete) {
  return {
    type: CHANGE_MIETE,
    miete
  };
}
