import React from 'react';
import NumberFormat from 'react-number-format';

const Currency = props => {
  return (
    <NumberFormat
      value={props.children}
      displayType={'text'}
      thousandSeparator={true}
      suffix={'€'}
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );
};

export default Currency;
