import React from 'react';

// Opt-out function
const gaProperty = 'UA-133719315-1';
const disableStr = 'ga-disable-' + gaProperty;
const gaOptout = () => {
  document.cookie =
    disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
  window[disableStr] = true;
};

const Impressum = () => {
  return (
    <React.Fragment>
      <div className="content">
        <h3 className="title">Impressum</h3>
        <p>
          Für den Inhalt verantwortlich:{' '}
          <a href="https://www.wolfib.com/about">Wolfgang Beyer</a>, Wien,{' '}
          <a href="mailto:wolfgang@kaufOderMiete.at">
            wolfgang@kaufOderMiete.at
          </a>
        </p>
        <hr className="hr" />
        <h3 className="title">Datenschutzerklärung</h3>
        <p>
          Diese Website bemüht sich, möglichst wenige persönliche Daten zu
          erfassen und zu verarbeiten. Einzig im Zusammenhang mit der Analyse
          der Benutzung dieser Website werden überhaupt Daten gesammelt, und das
          in einem möglichst geringem Ausmaß. Ich bin eigentlich nur daran
          interessiert zu wissen, wie viele Besucher diese Website im Laufe der
          Zeit hat.
        </p>
        <p>Hier noch die Details:</p>
        <p>
          Diese Website verwendet Funktionen des Webanalysedienstes Google
          Analytics der Google LLC („Google“). Dies geschieht auf Grundlage des
          berechtigten Interesses im Sinne der DSGVO an der Analyse und
          Verbesserung der Website.
        </p>
        <p>
          Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet
          hierdurch eine Garantie, das europäische Datenschutzrecht einzuhalten
          (
          <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active">
            https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active
          </a>
          ).
        </p>
        <p>
          Google verwendet Cookies. Die durch das Cookie erzeugten Informationen
          über Benutzung des Onlineangebotes durch die Nutzer werden in der
          Regel an einen Server von Google in den USA übertragen und dort
          gespeichert.
        </p>
        <p>
          Sie können dies verhindern, indem Sie Ihren Browser so einrichten,
          dass keine Cookies gespeichert werden. Sie können darüber hinaus die
          Erfassung der durch das Cookie erzeugten und auf ihre Nutzung des
          Onlineangebotes bezogenen Daten an Google sowie die Verarbeitung
          dieser Daten durch Google verhindern, indem sie das unter folgendem
          Link verfügbare Browser-Plugin herunterladen und installieren:
          <a href="http://tools.google.com/dlpage/gaoptout?hl=de">
            http://tools.google.com/dlpage/gaoptout?hl=de
          </a>
          . Außerdem können Sie über den folgenden Button die Erfassung von
          Daten durch Google Analytics für diese Website deaktivieren: <br />
          <button className="button" onClick={gaOptout}>
            Google Analytics für diese Website deaktivieren
          </button>
        </p>

        <p>
          Es wurde mit Google ein Vertrag zur Auftragsdatenverarbeitung
          abgeschlossen.
        </p>
        <p>
          Google Analytics wird nur mit aktivierter IP-Anonymisierung
          eingesetzt. Ihre IP-Adresse wird erfasst, aber umgehend
          pseudonymisiert. Dadurch ist nur mehr eine grobe Lokalisierung
          möglich. Die von dem Browser des Nutzers übermittelte IP-Adresse wird
          nicht mit anderen Daten von Google zusammengeführt.
        </p>
        <p>Die Nutzerdaten werden für die Dauer von 14 Monaten aufbewahrt.</p>
        <hr className="hr" />
      </div>
    </React.Fragment>
  );
};

export default Impressum;
